.edit-mode-side-navigation__headline {
  padding: 0 calc(2 * var(--layout-spacing-unit));
  margin-bottom: calc(2 * var(--layout-spacing-unit));
}
.edit-mode-side-navigation__form {
  height: auto;
  overflow: auto;
  padding: calc(2 * var(--layout-spacing-unit));
  padding-top: 0;
}
.edit-mode-side-navigation__form .form-element:first-child {
  margin-top: 0;
}
.edit-mode-side-navigation__form--unfocusable {
  overflow: hidden;
}
.edit-mode-side-navigation__form input {
  width: 18rem;
}
.edit-mode-side-navigation__form input[type=checkbox] {
  margin-left: 0;
}
.edit-mode-side-navigation__form .image-upload:first-child label {
  margin-top: 0;
}
.edit-mode-side-navigation__save-button {
  margin: calc(2 * var(--layout-spacing-unit));
}
/*# sourceMappingURL=src/components/navigation/EditModeSideNavigation.css.map */