html
  margin:0
  padding: 0
  font-size: var(--font-size-html)

body
  margin:0
  padding: 0
  font-family: 'Open Sans', sans-serif
  font-weight: normal
  line-height: var(--font-lineheight-normal)
  font-size: var(--font-size-body)
  color: var(--font-color-contrast-high)
  margin-top: var(--portal-header-height)
  background-color: var(--bgc-content-body)
  box-sizing: border-box

input,button,select,optgroup,textarea
  margin: 0
  font-family: inherit
  font-size: inherit
  line-height: inherit

button
  position: relative
  box-sizing: border-box
  padding: 0 calc(2 * var(--layout-spacing-unit))
  height: var(--button-size)
  color: var(--font-color-contrast-high)
  font-size: var(--button-font-size)
  font-weight: var(--font-weight-bold)
  text-transform: uppercase
  border: 0.2rem solid transparent
  border-radius: var(--button-border-radius)
  background-color: var(--button-bgc)
  cursor: pointer
  transition: var(--button-bgc-transition)
  display: flex
  align-items: center
  justify-content: center
  @media $mqSmartphone
    padding: 0 calc(1 * var(--layout-spacing-unit))
    font-size: var(--button-font-size-mobile)
  svg:not(:only-child)
    margin-right: var(--layout-spacing-unit-small)
  &:focus-visible
    border-color: var(--color-focus)
    outline: 0

  &:hover
    background-color: var(--button-bgc-hover)

  &.button--primary
    background-color: var(--button-primary-bgc)
    &:hover
      background-color: var(--button-primary-bgc-hover)
  &.button--shadow
    box-shadow: var(--box-shadow)
  &.button--flat
    background-color: transparent
    &:hover
      background-color: var(--button-bgc-hover)
    &--outline-style
      transition: border-color var(--portal-transition-duration)
      &:hover
        background-color: transparent
        border-color: var(--focus-color)
  &.button--icon
    width: var(--button-size)
    padding: 0
    &--circle
      border-radius: var(--border-radius-circles)
    &--inputfield-sized
      height: var(--inputfield-size)
      width: @height
    &--edit-mode
      transition: scale var(--portal-transition-duration) ease, var(--button-bgc-transition)
      &:hover
        scale: 1.2
    &--header-style
      &:hover
        background-color: transparent
        svg
          color: var(--color-accent)
    &--tab-style
      position: relative
      z-index: 10
      flex: 0 0 auto
      &--small
        width: calc(3 * var(--layout-spacing-unit))
        height: @width
      &:hover
        background-color: var(--portal-tab-close-button-hover) !important

  &[disabled]
    background-color: var(--button-bgc-disabled)
    color: var(--font-color-contrast-middle)
    cursor: var(--cursor-disabled)
    svg
      color: var(--font-color-contrast-middle)
    &:hover
      background-color: var(--button-bgc-disabled)

h2
  font-size: var(--font-size-2)
  line-height: var(--font-lineheight-normal)
  font-weight: 600

input, select, textarea
  box-sizing: border-box
  width: var(--inputfield-width)
  height: var(--inputfield-size)
  display: block
  padding: 0 calc(3 * var(--layout-spacing-unit-small)) !important;
  margin-bottom: var(--layout-spacing-unit)
  font-size: var(--font-size-3)
  color: var(--font-color-contrast-high)
  background-color: var(--bgc-inputfield-on-container)
  border: 0.1rem solid var(--bgc-inputfield-on-container)
  border-radius: var(--border-radius-interactable)
  transition: background-color, color, border
  transition-duration: var(--portal-transition-duration)

  @media $mqSmartphone
    width: 100%
    font-size: var(--font-size-4)
    padding: 0 calc(2 * var(--layout-spacing-unit-small)) !important;

  &:hover,
  &:focus
    border-color: var(--font-color-contrast-low)
    outline-style: none
    box-shadow: none

  &[invalid],
  &[aria-invalid=true]
    border-color: var(--font-color-error)

  &[disabled]
    cursor: var(--cursor-disabled)
    color: var(--font-color-contrast-low)

  &[type="radio"]
    height: 1rem
    width: @height
    margin-right: var(--layout-spacing-unit)
    appearance: none
    border-radius: 100%
    padding: 0 !important
    display: flex

    &:focus,
    &:focus:checked
      border-color: var(--color-focus)
    &:checked
      border-color: var(--color-accent)

    &:checked:after
      display: block;
      content: '';
      border-radius: 100%;
      height: 0.6rem;
      width: @height
      top: 5px;
      left: 5px;
      margin: auto;
      background: var(--color-accent)
select
  padding-right: calc(3 * var(--layout-spacing-unit)) !important

fieldset
  border: 0
  padding: 0

select
  cursor: pointer
  appearance: none
  position: relative
  background-image: var(--portal-select-arrow)
  background-repeat: no-repeat
  background-size: 1rem
  background-position: 98% center

legend, label, div.label
  display: block
  margin-top: calc(3 * var(--layout-spacing-unit))
  color: var(--font-color-contrast-middle)
  font-size: var(--font-size-3)
  position: relative

  @media $mqSmartphone
    margin-top: calc(1 * var(--layout-spacing-unit))
    font-size: var(--font-size-4)

input[type=checkbox]
  background-color: var(--bgc-inputfield-on-container)
  display: inline-block
  height: var(--font-size-3)
  width: var(--font-size-3)
  padding: 0 !important
  margin: 4px
  margin-right: var(--layout-spacing-unit)
  margin-left: 0
  border: 2px solid var(--font-color-contrast-low)
  border-radius: 2px
  cursor: pointer
  appearance: none
  &:focus,
  &:focus:checked
    border-color: var(--color-focus)
  &:checked
    border-color: var(--color-accent)

  & + span
    vertical-align: top
    cursor: pointer

  &:checked:after
    content: "✓"
    display: block
    width: 100%
    height: 100%
    line-height: 111%
    font-size: 0.7rem
    font-weight: bold
    text-align: center
    color: var(--color-accent)

textarea
  max-width: 100%
  padding-top: calc(3 * var(--layout-spacing-unit-small)) !important
  height: calc(10 * var(--font-size-3))

// --------------
// global classes
.body--lock-scrollbar
  position: fixed
  overflow-y: scroll
  bottom: 0
  left: 0
  right: 0

.body--hide-scrollbar
  overflow: hidden

.portal-reset
  line-height: inherit

.portal
  &__off-screen
    position: absolute !important
    left: -10000px !important
    top: -10000px !important

// animation
.fade-enter-active,
.fade-leave-active
  transition: opacity .5s

.fade-enter,
.fade-leave-to
  opacity: 0

.sr-only
  position: absolute
  width: 1px
  height: 1px
  padding: 0
  margin: -1px
  overflow: hidden
  clip: rect(0,0,0,0)
  border: 0

/* this class is needed, because an @extend wouln't work on a @media */
.sr-only-mobile
  @media $mqSmartphone
    position: absolute
    width: 1px
    height: 1px
    padding: 0
    margin: -1px
    overflow: hidden
    clip: rect(0,0,0,0)
    border: 0
