.portal-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;
  background-color: var(--bgc-content-header);
  color: var(--font-color-contrast-high);
  height: var(--portal-header-height);
  display: flex;
  padding: 0 calc(2 * var(--layout-spacing-unit));
  width: 100%;
  box-sizing: border-box;
}
.portal-header__tabs {
  display: flex;
  flex: 1 1 auto;
  margin-left: calc(5 * var(--layout-spacing-unit));
  width: 100%;
  overflow: hidden;
  align-items: center;
}
.portal-header__right {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.edit-mode-wrapper {
  position: fixed;
  left: 0;
  top: calc(var(--layout-height-header) + var(--layout-spacing-unit));
  right: 0;
  pointer-events: none;
  display: flex;
  gap: var(--layout-spacing-unit);
  flex: 1 0 auto;
  align-items: center;
}
.edit-mode-wrapper-divider {
  flex: 1 1 0;
}
.edit-mode-wrapper-divider-right {
  min-width: 23rem;
}
.edit-mode-wrapper button {
  pointer-events: all;
}
@media only screen and (max-width: 748px) {
  .edit-mode-wrapper {
    position: relative;
    top: 0;
  }
  .edit-mode-wrapper-divider-right {
    min-width: auto;
  }
}
#header-button-copy {
  display: none;
}
.portal-header--tabs-overflow .portal-header__tabs {
  visibility: hidden;
}
.portal-header--tabs-overflow #header-button-copy {
  display: flex;
}
.portal-header--edit-mode .portal-header__tabs {
  display: none;
}
#announcement-container {
  display: flex;
  padding: var(--layout-spacing-unit);
  flex-direction: column;
  gap: var(--layout-spacing-unit);
  position: relative;
}
#announcement-container:empty {
  display: none;
}
#announcement-container:has(.announcement:not(.announcement--sticky)) .announcement--sticky .announcement__closeWrapper {
  display: block;
}
#announcement-container:has(.announcement:not(.announcement--sticky)) .announcement--sticky .announcement__closeButton {
  visibility: hidden;
}
/*# sourceMappingURL=src/components/PortalHeader.css.map */