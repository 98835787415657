.portal-sidenavigation {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.portal-sidenavigation__link {
  position: relative;
  left: calc(2 * var(--layout-spacing-unit));
  margin-top: var(--layout-spacing-unit);
  margin-bottom: calc(2 * var(--layout-spacing-unit));
  align-self: flex-start;
}
.portal-sidenavigation__user-row {
  display: flex;
  height: 6rem;
}
.portal-sidenavigation__user-icon {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-apptile);
  margin: 1rem;
  border: 1px solid var(--portal-sidenav-user-icon);
  width: 3rem;
  height: 3rem;
  margin: 24px 12px 24px 20px;
  padding-left: 0 !important;
}
.portal-sidenavigation__user-icon svg {
  fill: currentColor;
  height: 3rem;
  width: 3rem;
  border-radius: var(--border-radius-circles);
  color: var(--portal-sidenav-user-icon);
  margin: 0;
}
.portal-sidenavigation__user-text-content {
  margin: auto 0;
  padding: 0 var(--layout-spacing-unit);
  height: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: calc(1rem + var(--layout-spacing-unit)) 0;
  box-sizing: border-box;
}
.portal-sidenavigation--username {
  font-weight: bold;
  font-size: var(--font-size-html);
}
.portal-sidenavigation__logout-link {
  scale: 85%;
  margin-left: -0.5rem;
}
.portal-sidenavigation__logout-link:focus-visible span {
  text-decoration: none;
}
.portal-sidenavigation__login {
  margin-top: calc(2 * var(--layout-spacing-unit));
}
.portal-sidenavigation__login span {
  margin: 0.2rem;
}
.portal-sidenavigation__login-header:focus-visible {
  outline: 0;
}
.portal-sidenavigation__menu {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.portal-sidenavigation__menu-item {
  margin-left: 0;
}
.portal-sidenavigation__menu-item--show {
  display: block;
}
.portal-sidenavigation__menu-item--hide {
  display: none;
}
.portal-sidenavigation__menu-subItem {
  margin-left: 0;
  transition: background-color var(--portal-transition-duration);
}
.portal-sidenavigation__menu-subItem--parent {
  text-transform: uppercase;
  padding-left: 4rem;
  margin-bottom: 1rem;
}
.portal-sidenavigation__menu-subItem:hover {
  background-color: var(--bgc-user-menu-item-hover);
}
.portal-sidenavigation__fade-left-right,
.portal-sidenavigation__fade-right-left {
  animation-duration: 250ms;
}
.portal-sidenavigation__fade-right-left {
  animation-name: fadeOutRight;
}
.portal-sidenavigation__fade-left-right {
  animation-name: fadeInLeft;
}
.divider {
  flex: 0 0 auto;
  background-color: var(--bgc-user-menu-item-hover);
  width: 90%;
  height: 2px;
  position: relative;
  left: calc(2 * var(--layout-spacing-unit));
  margin-bottom: var(--layout-spacing-unit);
}
.divider--bottom {
  margin-top: var(--layout-spacing-unit);
}
@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-moz-keyframes fadeOutRight {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-o-keyframes fadeOutRight {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeOutRight {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*# sourceMappingURL=src/components/navigation/SideNavigation.css.map */