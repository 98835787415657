.link-widget {
  display: flex;
  align-items: center;
  margin-bottom: var(--layout-spacing-unit);
}
.link-widget__select {
  flex: 0 0 auto;
}
.link-widget__input {
  flex: 1 1 auto;
  margin-left: var(--layout-spacing-unit);
  margin-right: var(--layout-spacing-unit);
}
.link-widget__input input {
  width: 100%;
}
.link-widget__remove {
  flex: 0 0 auto;
}
/*# sourceMappingURL=src/components/widgets/LinkWidget.css.map */