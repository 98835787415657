:root
	// color palette
	$color-grey0  = #1E1E1D
	$color-grey8  = #333331
	$color-grey25 = #5F5F5C
	$color-grey40 = #868681
	$color-grey62 = #BDBDBB


	/* used to accent some elements: checkbox/radiobox, checked toggle buttons/checked input fields */
	--color-accent:       #7AB51D

	// background colors
	--bgc-content-body:      $color-grey8
	--bgc-content-header:    var(--bgc-content-container)
	--bgc-content-container: $color-grey0

	--bgc-inputfield-on-container: var(--bgc-content-body)
	--bgc-inputfield-on-body:      var(--bgc-content-container)

	--bgc-checkbox-hover: rgba(255, 255, 255, 0.06)
	--bgc-checkbox-focus: rgba(255, 255, 255, 0.12)

	--bgc-loading-circle: var(--font-color-contrast-middle)

	--bgc-user-menu-item-hover:  rgba(255, 255, 255, 0.1)
	--bgc-user-menu-item-active: rgba(255, 255, 255, 0.2)

	--bgc-header-number-circle: $color-grey62

	--bgc-tab-separator: $color-grey25

	--bgc-popup:               $color-grey25
	--bgc-popup-item-hover:    rgba(0, 0, 0, 0.15)
	--bgc-popup-item-active:   rgba(0, 0, 0, 0.3)
	--bgc-popup-item-selected: rgba(0, 0, 0, 0.3)

	--bgc-grid-row-hover:    rgba(255, 255, 255, 0.04)
	--bgc-grid-row-selected: var(--bgc-grid-row-hover)
	--bgc-tree-row-hover:    var(--bgc-grid-row-hover)
	--bgc-tree-row-selected: rgba(255, 255, 255, 0.15)

	--bgc-apptile-default:      $color-grey40
	--bgc-appcenter-app-hover:  rgba(255, 255, 255, 0.08)
	--bgc-appcenter-app-active: rgba(255, 255, 255, 0.12)

	--bgc-progressbar-empty:    $color-grey62
	--bgc-progressbar-progress: var(--bgc-success)

	--bgc-titlepane-hover: rgba(255, 255, 255, 0.04)

	--bgc-underlay: s('rgba(%s, %s, %s, 0.8)', red(@--bgc-content-body), green(@--bgc-content-body), blue(@--bgc-content-body))

	--bgc-checkerboard: repeating-conic-gradient(var(--bgc-inputfield-on-container) 0% 25%, transparent 0% 50%) 50% / 20px 20px

	--bgc-error:   #E4100E
	--bgc-warning: #975607
	--bgc-success: #4A6D12

	--bgc-announcements-info: var(--color-accent)
	--bgc-announcements-danger: var(--bgc-error)
	--bgc-announcements-success: var(--bgc-success)
	--bgc-announcements-warn: var(--bgc-warning)

	// font
	--font-size-1: 1.5rem
	--font-size-2: 1.25rem
	--font-size-3: 1rem
	--font-size-4: 0.875rem
	--font-size-5: 0.75rem

	--font-size-html: 1rem
	--font-size-body: var(--font-size-4)

	--font-lineheight-normal: 1.5
	--font-lineheight-compact: 1.25
	--font-lineheight-header: 1.3
	--font-weight-bold: 600

	--font-color-contrast-high:   #FFFFFF
	--font-color-contrast-middle: $color-grey62
	--font-color-contrast-low:    $color-grey40
	--font-color-error:           #F0758C
	--font-color-warning:         #FF8C00
	--font-color-success:         #9CD147


	// buttons
	--button-primary-bgc:          #5E881D
	--button-primary-bgc-hover:    blend(rgba(0, 0, 0, 0.08), @--button-primary-bgc)
	--button-primary-bgc-active:   blend(rgba(0, 0, 0, 0.16), @--button-primary-bgc)
	--button-primary-bgc-disabled: $color-grey25

	--button-bgc:          $color-grey25
	--button-bgc-hover:    blend(rgba(0, 0, 0, 0.08), $color-grey25)
	--button-bgc-active:   blend(rgba(0, 0, 0, 0.16), $color-grey25)
	--button-bgc-disabled: $color-grey25

	--button-text-bgc:          transparent
	--button-text-bgc-hover:    var(--button-bgc-hover)
	--button-text-bgc-active:   var(--button-bgc-active)
	--button-text-bgc-disabled: transparent

	--button-icon-bgc:          transparent
	--button-icon-bgc-hover:    var(--button-bgc-hover)
	--button-icon-bgc-active:   var(--button-bgc-active)
	--button-icon-bgc-disabled: transparent

	--button-icon-highlighted-bgc:          var(--bgc-inputfield-on-body)
	--button-icon-highlighted-bgc-hover:    var(--button-bgc-hover)
	--button-icon-highlighted-bgc-active:   var(--button-bgc-active)
	--button-icon-highlighted-bgc-disabled: transparent


	// focus
	--color-focus: var(--font-color-contrast-high)


	// popups
	--popup-border: 1px solid $color-grey62


	// shadows
	--box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)


	// server overview
	--serveroverview-tile-hover-bgc:   #00ACB6
	--serveroverview-tile-hover-color: #1E1E1D


	// portal
	--bgc-portal-tab:                    $color-grey8
	--bgc-portal-tab-active:             $color-grey25
	--bgc-portal-tab-hover:              $color-grey25
	--bgc-portal-tab-close-button-hover: $color-grey8
	--bgc-portal-scrollbar:              $color-grey8

	--color-portal-iframe-border:        $color-grey25
	--color-portal-user-icon:            $color-grey25

	--select-arrow-portal: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAGlJREFUSIntkjEKwCAMRb1Epb3/UdzEqQ49zuvQgCJFY+uYN2X4+Q9CnDMM4xUgAAnwiqyXbJgRRB5yTyLlWbJxRrABpyxewKHI7GrBSPK7vCdZVl5J6jvnZh4+wRfJ2vJGkrTvaxhG4QZrd94ATkf1aAAAAABJRU5ErkJggg==')
