
.portal-tooltip
  position: fixed
  background-color: var(--bgc-content-container)
  border-radius: var(--border-radius-container)
  min-width: calc(20 * 1rem)
  max-width: calc(20 * 1rem)
  padding: calc(2 * var(--layout-spacing-unit))
  box-shadow: var(--box-shadow)
  z-index: $zindex-3
  z-index: 99999
  display: block
  border: 1px solid var(--font-color-contrast-high)

  @media $mqSmartphone
    bottom: unset;
    top: calc(3 * var(--layout-spacing-unit))
    min-width: 4rem
    max-width: 84vw
    width: 90%
    left:0
    right:0
    margin-left:auto
    margin-right:auto
    font-size: var(--font-size-5)
    pointer-events: auto

  &__header
    display: flex
    align-items: center

    @media $mqSmartphone
      margin-bottom: calc(1 * var(--layout-spacing-unit))

  &__thumbnail
    border-radius: var(--border-radius-apptile)
    display: flex
    align-items: center
    justify-content: center
    box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16)
    background-color: var(--bgc-apptile-default)

    .portal-tooltip__header &
      width: calc(3 * 1rem)
      height: calc(3 * 1rem)
      margin-right: calc(3 * calc(1rem / 2))

      @media $mqSmartphone
        height: calc(4 * var(--layout-spacing-unit))
        width: @height
        margin-right: calc(1 * var(--layout-spacing-unit))

  &__logo
    width: 80%
    max-height: 80%
    vertical-align: middle
    border: 0

  &__close-icon
    display: none
    @media $mqSmartphone
      display: flex
      margin-left: auto

  &__arrow
    display: block
    position: absolute
    width: 0;
    height: 0;
    border: solid var(--layout-spacing-unit);
    border-color: transparent transparent var(--font-color-contrast-high) transparent;

  &__inner-wrap
    position: relative
    width: 100%
    height: 100%

  &__link-type
    text-align: right
    font-size: var(--font-size-5)
    display: flex
    align-items: center
    justify-content: flex-end
    margin-top: 0.3rem

  &__link-type-icon
    margin-left: 0.3rem
    width: 0.8rem
    &--same-tab
      transform: rotate(90deg)
  .cheat
    width: calc(100%);
    height: calc(100% + 75%);
    position absolute
    z-index: -1
    top: -38%;
    left: 0;

.fade-enter-active {
  transition: all 0.25s ease-out
}

.fade-leave-active {
  transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1)
}
