
.portal-header
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: $zindex-3
  background-color: var(--bgc-content-header)
  color: var(--font-color-contrast-high)
  height: var(--portal-header-height)
  display: flex
  padding: 0 calc(2 * var(--layout-spacing-unit))
  width: 100%
  box-sizing: border-box

  &__tabs
    display: flex;
    flex: 1 1 auto;
    margin-left: calc(5 * var(--layout-spacing-unit));
    width: 100%;
    overflow: hidden
    align-items: center

  &__right
    margin-left: auto
    display: flex;
    align-items: center;

.edit-mode-wrapper
  position: fixed
  left: 0
  top: calc(var(--layout-height-header) + var(--layout-spacing-unit))
  right: 0
  pointer-events: none
  display: flex
  gap: var(--layout-spacing-unit)
  flex: 1 0 auto
  align-items: center
  &-divider
    flex: 1 1 0
    &-right
      min-width: 23rem
  button
    pointer-events: all
@media $mqSmartphone
  .edit-mode-wrapper
    position: relative
    top: 0
    &-divider-right
      min-width: auto

#header-button-copy
    display: none

.portal-header
  &--tabs-overflow
    .portal-header__tabs
      visibility: hidden
    #header-button-copy
      display: flex
  &--edit-mode
    .portal-header__tabs
      display: none

#announcement-container
  display: flex
  padding: var(--layout-spacing-unit)
  flex-direction: column
  gap: var(--layout-spacing-unit)
  position: relative
  &:empty
    display: none

#announcement-container:has(.announcement:not(.announcement--sticky)) .announcement--sticky
  .announcement__closeWrapper
    display: block
  .announcement__closeButton
    visibility: hidden
