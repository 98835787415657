* ::-webkit-scrollbar {
  width: 0.25rem;
}
* ::-webkit-scrollbar-track {
  background: var(--portal-scrollbar-background);
}
* ::-webkit-scrollbar-thumb {
  background: var(--font-color-contrast-low);
  border-radius: 2rem;
}
* ::-webkit-scrollbar-thumb:hover {
  background: var(--font-color-contrast-middle);
}
/*# sourceMappingURL=src/App.css.map */