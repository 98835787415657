
.portal-categories
  position: relative;
  padding: calc(4 * var(--layout-spacing-unit)) calc(6 * var(--layout-spacing-unit));

  @media $mqSmartphone
    padding: calc(4 * var(--layout-spacing-unit)) calc(4 * var(--layout-spacing-unit));

  &__menu-wrapper
    width: 100%
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    position: absolute

  &__menu-container
    position: relative
    order: 0;
    flex: 0 1 auto;
    align-self: auto;

  &__icon
    position: absolute
    right: 15px
    margin-top: 2px

.portal--edit-mode .portal-categories
  padding-top: calc(6 * var(--layout-spacing-unit))

.portal-iframes
  position: fixed
  top: var(--portal-header-height)
  border: 0 solid var(--portal-iframe-border)
  border-top-width: 0.1rem
  right: 0
  bottom: 0
  left: 0

@keyframes fadeIn
  from
    opacity: 0
    scale: 80%

  to
    opacity: 1
    scale: 100%

.portal--play-tile-animation .portal-tile__box
  animation: fadeIn
  animation-duration: 0.25s
