@import "./_ucs-vars.styl"
@import "./_variables.styl"

:root
  // portal
  --portal-header-height: var(--layout-height-header)
  --border-radius-apptile: 12.5% // 20px
  --border-radius-apptile-in-folder: 12.5% // 5px
  --border-radius-apptile-in-tab: 12.5% // 2px
  --border-radius-portal-folder: 10%
  --portal-transition-duration: 150ms
  --app-tile-side-length: 8rem
  --portal-folder-tile-width: 33%
  --portal-folder-padding: calc(4 * var(--layout-spacing-unit))
  --portal-tab-background: var(--bgc-portal-tab)
  --portal-tab-background-active: var(--bgc-portal-tab-active)
  --portal-tab-background-hover: var(--bgc-portal-tab-hover)
  --portal-tab-close-button-hover: var(--bgc-portal-tab-close-button-hover)
  --portal-scrollbar-background: var(--bgc-portal-scrollbar)
  --portal-iframe-border: var(--color-portal-iframe-border)
  --portal-sidenav-user-icon: var(--color-portal-user-icon)
  --portal-header-icon-scale: 0.5
  --portal-select-arrow: var(--select-arrow-portal)
  --inputfield-width: 20rem

@media $mqSmartphone
  :root
    --portal-folder-tile-width: 50%
    --portal-folder-padding: calc(2 * var(--layout-spacing-unit))

    --app-tile-side-length: 5.5rem
