.portal-title {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px 10px;
  border: 0.2rem solid rgba(0,0,0,0);
  border-radius: var(--border-radius-interactable);
  box-sizing: border-box;
  height: 100%;
}
.portal-title:focus-visible {
  border: 0.2rem solid var(--color-focus);
  outline: 0;
}
.portal-title__image {
  height: 100%;
}
.portal-title__portal-home-icon {
  display: none;
}
@media only screen and (max-width: 748px) {
  .portal-title__portal-home-icon {
    display: flex;
    align-content: center;
  }
  .portal-title__portal-home-icon svg {
    width: calc(3 * var(--layout-spacing-unit));
    height: calc(3 * var(--layout-spacing-unit));
  }
}
.portal-title__portal-name {
  font-size: var(--font-size-2);
  white-space: nowrap;
  padding-left: var(--layout-spacing-unit);
}
/*# sourceMappingURL=src/components/header/PortalTitle.css.map */