.header-tab {
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1;
  background-color: transparent;
  flex-basis: auto;
  flex-grow: 1;
  max-width: 15rem;
  border: 0.2rem solid transparent;
}
.header-tab__wrapper {
  display: flex;
  position: relative;
  align-items: center;
  height: 40px;
  margin-right: var(--layout-spacing-unit-small);
  background-color: var(--portal-tab-background);
  border-radius: var(--border-radius-interactable);
  transition: background-color var(--portal-transition-duration);
}
.header-tab__wrapper:hover {
  background-color: var(--portal-tab-background-hover);
}
.header-tab__logo-wrapper {
  background-color: var(--bgc-apptile-default);
  border-radius: var(--border-radius-apptile);
  height: calc(var(--portal-header-height) * var(--portal-header-icon-scale));
  width: calc(var(--portal-header-height) * var(--portal-header-icon-scale));
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 var(--layout-spacing-unit) 0 var(--layout-spacing-unit-small);
}
.header-tab__logo {
  width: 80%;
  max-height: 80%;
  vertical-align: middle;
  border: 0;
}
.header-tab__title {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 2.5rem;
  font-weight: var(--font-weight-bold);
}
.header-tab__close-button {
  margin: 0 var(--layout-spacing-unit-small);
}
.header-tab__clickable:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0.2rem solid rgba(0,0,0,0);
  box-sizing: border-box;
}
.header-tab__clickable:focus-visible:before {
  border-color: var(--color-focus);
  border-radius: var(--button-border-radius);
}
.header-tab--active:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0.2rem solid rgba(0,0,0,0);
  box-sizing: border-box;
  border-radius: var(--border-radius-interactable);
  z-index: -1;
  background-color: var(--portal-tab-background-active);
}
/*# sourceMappingURL=src/components/navigation/HeaderTab.css.map */