
.modal-wrapper
    position: fixed
    width: 100%
    height: 100%
    top: 0
    right: 0
    bottom: 0
    left: 0
    background-color: var(--bgc-underlay)
    pointer-events: none

    &--isVisible
      position: fixed
      width: 100%
      height: 100vh
      top: 0
      right: 0
      bottom: 0
      left: 0
      z-index: $zindex-2
      background-color: var(--bgc-underlay)
      display: flex
      align-items: center
      justify-content: center
      &:not(.modal-wrapper--selfservice)
        flex-direction: column

      &> *
        position: relative
        z-index: 1

    &--isSecondLayer
      z-index: $zindex-3

      &> *
        position: relative
        z-index: 1

    &--isVisibleFullscreen
      z-index: $zindex-4

.modalWrapperFade-enter-active,
.modalWrapperFade-leave-active
  transition: opacity 0.2s ease

.modalWrapperFade-enter-from,
.modalWrapperFade-leave-to
  opacity: 0

.modalWrapperFade-enter-from .flyout-wrapper,
.modalWrapperFade-leave-to .flyout-wrapper
  transform: translate3d(110%, 0, 0)
