
$userRow = 6rem
.portal-sidenavigation
  height: 100%
  display: flex
  flex-direction: column

  &__link
    position: relative
    left: calc(2*var(--layout-spacing-unit))
    margin-top: var(--layout-spacing-unit)
    margin-bottom: calc(2*var(--layout-spacing-unit))
    align-self: flex-start

  &__user-row
    display: flex
    height: $userRow

  &__user-icon
    position: relative
    overflow: hidden;
    border-radius: var(--border-radius-apptile)
    margin: 1rem
    border: 1px solid var(--portal-sidenav-user-icon)
    width: 3rem
    height: @width
    margin: 24px 12px 24px 20px
    padding-left: 0 !important; // remove this line, when weird server caching is fixed

    svg
      fill: currentColor
      height: 3rem
      width: @height
      border-radius: var(--border-radius-circles)
      color: var(--portal-sidenav-user-icon)
      margin: 0

  &__user-text-content
    margin: auto 0
    padding: 0 var(--layout-spacing-unit)
    height: 100%;
    align-items: flex-start
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: calc(1rem + var(--layout-spacing-unit)) 0
    box-sizing: border-box

  &--username
    font-weight: bold
    font-size: var(--font-size-html)

  &__logout-link
    scale: 85%
    margin-left: -0.5rem

    &:focus-visible span
      text-decoration: none

  &__login
    margin-top: calc(2*var(--layout-spacing-unit))

    span
        margin: 0.2rem

  &__login-header
    &:focus-visible
      outline: 0

  &__menu
    flex: 1 1 auto
    overflow-y: auto
    overflow-x: hidden

  &__menu-item
    margin-left: 0

    &--show
      display: block

    &--hide
      display: none

  &__menu-subItem
    margin-left: 0
    transition: background-color var(--portal-transition-duration)
    &--parent
      text-transform: uppercase
      padding-left: 4rem
      margin-bottom: 1rem
    &:hover
      background-color: var(--bgc-user-menu-item-hover)

  &__fade-left-right,
  &__fade-right-left
    animation-duration: 250ms

  &__fade-right-left
    animation-name: fadeOutRight

  &__fade-left-right
    animation-name: fadeInLeft

// keyframes
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.divider
  flex: 0 0 auto
  background-color: var(--bgc-user-menu-item-hover)
  width: 90%
  height: 2px
  position: relative
  left: calc(2*var(--layout-spacing-unit))
  margin-bottom: var(--layout-spacing-unit)
  &--bottom
    margin-top: var(--layout-spacing-unit)
