.choose-tab {
  display: flex;
  align-items: center;
  position: relative;
  margin: var(--layout-spacing-unit-small) 0;
  padding: var(--layout-spacing-unit);
  border-radius: var(--border-radius-interactable);
  width: 20rem;
  transition: background-color var(--portal-transition-duration) ease;
  background: var(--portal-tab-background);
}
.choose-tab:hover {
  background-color: var(--portal-tab-background-hover);
}
@media only screen and (max-width: 748px) {
  .choose-tab {
    max-width: 100%;
  }
}
.choose-tab:first-of-type {
  margin-top: 0.2rem;
}
.choose-tab--active {
  background-color: var(--portal-tab-background-active);
}
.choose-tab__button {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 2px solid transparent;
  width: 100%;
}
.choose-tab__button:focus-visible {
  outline: 0;
}
.choose-tab__button:focus-visible:before {
  border-color: var(--color-focus);
  border-radius: var(--button-border-radius);
}
.choose-tab__button:before {
  content: '';
  width: auto;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  border: 0.2rem solid transparent;
}
.choose-tab__img {
  width: 80%;
  max-height: 80%;
  vertical-align: middle;
  border: 0;
}
.choose-tab__logo-wrapper {
  background-color: var(--bgc-apptile-default);
  border-radius: var(--border-radius-apptile);
  height: var(--button-size);
  width: var(--button-size);
  min-width: var(--button-size);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 var(--layout-spacing-unit) 0 0;
}
.choose-tab .button--icon {
  margin-left: var(--layout-spacing-unit);
}
/*# sourceMappingURL=src/components/ChooseTabs.css.map */