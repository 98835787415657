
.link-widget
  display: flex
  align-items: center
  margin-bottom: var(--layout-spacing-unit)

  &__select
    flex: 0 0 auto

  &__input
    flex: 1 1 auto
    margin-left: var(--layout-spacing-unit)
    margin-right: var(--layout-spacing-unit)

    input
      width: 100%

  &__remove
    flex: 0 0 auto
