.portal-sidebar__title {
  margin: calc(2 * var(--layout-spacing-unit)) 0;
  margin-left: calc(2.5 * var(--layout-spacing-unit));
  font-size: 20px;
  font-weight: normal;
}
.slide-enter-active,
.slide-leave-active {
  transition: transform var(--portal-transition-duration) ease;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(22rem);
}
/*# sourceMappingURL=src/components/PortalSidebar.css.map */