
.flyout-wrapper
  .notification__closing-svg
    display: none

.notification
  border-radius: var(--border-radius-notification)
  margin-bottom: calc(2 * var(--layout-spacing-unit))
  padding: var(--layout-spacing-unit)
  padding-left: calc(3 * var(--layout-spacing-unit))

  &.notification__dismissing
    .notification__closing-svg circle
      stroke-dashoffset: 283
      transition: stroke-dashoffset linear var(--closing-duration)

  &__closing-button
    transition: none !important
  &__closing-button:hover, &__closing-button:focus
    .notification__closing-svg
      display: none

  &__closing-svg
    position: absolute
    top: -2px
    left: -2px
    right: -2px
    bottom: -2px
    pointer-events: none

    circle
      display: block
      fill: transparent
      stroke: var(--color-focus)
      stroke-linecap: round
      stroke-dasharray: 283
      stroke-dashoffset: 0
      stroke-width: 0.5rem
      transform-origin: 50% 50%
      transform: scale(1, -1) rotate(90deg)

  &--default
    background-color: var(--bgc-popup)

  &--success
    background-color: var(--bgc-success)

  &--warning
    background-color: var(--bgc-warning)

  &--error
    background-color: var(--bgc-error)

  &__header
    display: flex
    align-items: center

  &__title
    flex: 1 1 auto

  &__description
    padding: 0 !important
    overflow: auto
    margin-top: var(--layout-spacing-unit)
    padding-right: calc(2 * var(--layout-spacing-unit))

    &>a
      color: var(--color-white) !important
      transition: color var(--portal-transition-duration)
      text-decoration: underline

    ul
      padding-left: calc(2* var(--layout-spacing-unit))
      margin-top: 0

  svg
    margin-right: 0!important
