.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--bgc-underlay);
  pointer-events: none;
}
.modal-wrapper--isVisible {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background-color: var(--bgc-underlay);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-wrapper--isVisible:not(.modal-wrapper--selfservice) {
  flex-direction: column;
}
.modal-wrapper--isVisible> * {
  position: relative;
  z-index: 1;
}
.modal-wrapper--isSecondLayer {
  z-index: 300;
}
.modal-wrapper--isSecondLayer> * {
  position: relative;
  z-index: 1;
}
.modal-wrapper--isVisibleFullscreen {
  z-index: 400;
}
.modalWrapperFade-enter-active,
.modalWrapperFade-leave-active {
  transition: opacity 0.2s ease;
}
.modalWrapperFade-enter-from,
.modalWrapperFade-leave-to {
  opacity: 0;
}
.modalWrapperFade-enter-from .flyout-wrapper,
.modalWrapperFade-leave-to .flyout-wrapper {
  transform: translate3d(110%, 0, 0);
}
/*# sourceMappingURL=src/components/modal/ModalWrapper.css.map */