.portal-categories {
  position: relative;
  padding: calc(4 * var(--layout-spacing-unit)) calc(6 * var(--layout-spacing-unit));
}
@media only screen and (max-width: 748px) {
  .portal-categories {
    padding: calc(4 * var(--layout-spacing-unit)) calc(4 * var(--layout-spacing-unit));
  }
}
.portal-categories__title {
  display: inline-block;
  margin-top: 0;
  margin-bottom: calc(6 * var(--layout-spacing-unit));
}
.portal-categories__menu-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  position: absolute;
}
.portal-categories__menu-container {
  position: relative;
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
}
.portal-categories__icon {
  position: absolute;
  right: 15px;
  margin-top: 2px;
}
.portal-iframes {
  position: fixed;
  top: var(--portal-header-height);
  border: 0 solid var(--portal-iframe-border);
  border-top-width: 0.1rem;
  right: 0;
  bottom: 0;
  left: 0;
}
/*# sourceMappingURL=src/views/NotFound.css.map */