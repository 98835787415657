
.header-button
  margin: 0 var(--layout-spacing-unit-small)

  &--is-active
      z-index:1000
      svg
        color: var(--color-accent)

  &__detail
    position: absolute
    color: var(--bgc-content-header)
    background-color: var(--bgc-header-number-circle)
    font-size: var(--font-size-5)
    width: 1.6em
    height: 1.6em
    left: 2em
    top: -0.5em
    border-radius: var(--border-radius-circles)
    display: flex
    align-items: center
    justify-content: center
    pointer-events: none

#header-button-bell svg
#header-button-copy svg
  margin-right: 0!important
