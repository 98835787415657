
.portal-tile
  position: relative
  outline: 0
  width: var(--app-tile-side-length)
  display: flex
  flex-direction: column
  align-items: center
  cursor: pointer
  color: var(--font-color-contrast-high)
  text-decoration: none

  &__root-element
    display:flex
    justify-content: center
    position: relative
  &__box
    border-radius: var(--border-radius-apptile)
    display: flex
    align-items: center
    justify-content: center
    box-shadow: var(--box-shadow)
    background-color: var(--bgc-apptile-default)
    width: var(--app-tile-side-length)
    height: @width
    margin-bottom: calc(2 * var(--layout-spacing-unit))
    border: 0.2rem solid transparent
    box-sizing: border-box

    ~/:focus-visible &
      border-color: var(--color-focus)

    &--with-scaling-hover
      transition: scale var(--portal-transition-duration) ease

      &:hover
        scale: 1.08

    &--dragged-line
      border: 3px solid pink

    &--dragging
      transform: rotate(-10deg)
    &--draggable
      position: relative

      &:after
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $zindex-1;

  &__img
    width: 80%
    max-height: 80%

  &__name
    font-weight: var(--font-weight-bold)
    text-align: center
    word-wrap: break-word
    hyphens: auto

  &__info-button,
  &__icon-bar
    position: absolute
    top: calc(-3 * var(--layout-spacing-unit-small))
    z-index: $zindex-1
    display: flex
    gap: var(--layout-spacing-unit)
  &__icon-bar
    right: calc(-1 * var(--layout-spacing-unit-small))
  &__info-button
    right: calc(-2 * var(--layout-spacing-unit-small))

  &__info-button
    font-size: var(--font-size-2)

    svg
      width: calc(1.5 * var(--button-icon-size))
      height: @width

  &__modal
    width: 650px
