
.tile-add
  display: flex
  flex-direction: column
  align-items: center
  border: 0; // TODO: Remove when weird servercaching is fixed

  &__button
    box-shadow: var(--box-shadow)
    margin: 0 0 calc(2 * var(--layout-spacing-unit)) 0
    min-width: var(--app-tile-side-length)
    width: var(--app-tile-side-length)
    height: var(--app-tile-side-length)
    border-radius: var(--border-radius-apptile)
    border: 0.2rem solid var(--button-bgc)
    background-color: transparent
    cursor: pointer
    box-sizing: border-box
    transition: scale var(--portal-transition-duration) ease

    &:hover
      scale: 1.08

    &:focus-visible
      border-color: var(--color-focus)

    &:focus-visible, &:hover
      background-color: transparent

    svg
      width: 100%
      height: 100%
      stroke: var(--button-bgc)

  .portal-tile__name
    white-space: inherit
