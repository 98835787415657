
.choose-tab
  display: flex
  align-items: center
  position:relative
  margin: var(--layout-spacing-unit-small) 0
  padding: var(--layout-spacing-unit)
  border-radius: var(--border-radius-interactable)
  width: 20rem
  transition: background-color var(--portal-transition-duration) ease
  background: var(--portal-tab-background)

  &:hover
    background-color: var(--portal-tab-background-hover)

  @media $mqSmartphone
    max-width: 100%

  &:first-of-type
    margin-top: 0.2rem

  &--active
    background-color: var(--portal-tab-background-active)

  &__button
    display: flex
    align-items: center
    cursor: pointer
    border: 2px solid transparent
    width: 100%

    &:focus-visible
      outline: 0

      &:before
        border-color: var(--color-focus)
        border-radius: var(--button-border-radius)

    &:before
      content: ''
      width: auto
      height: 100%
      position: absolute
      left: 0
      right: 0
      border: 0.2rem solid transparent

  &__img
    width: 80%
    max-height: 80%
    vertical-align: middle
    border: 0

  &__logo-wrapper
    background-color: var(--bgc-apptile-default)
    border-radius: var(--border-radius-apptile)
    height: var(--button-size)
    width: @height
    min-width: @height
    display: flex
    align-items: center
    justify-content: center
    margin: 0 var(--layout-spacing-unit) 0 0

  .button--icon
    margin-left: var(--layout-spacing-unit)
